<template>
  <div>
    <el-container>
      <el-main>
        <div class="search-top flex-content-between">
          <SearchInput
            class="search-input"
            placeholderStr="请输入部门名"
            @updateSearchList="updateSearchList"
            ref="refDepartmentDateInputSearch"
          />
          <el-button
            type="primary"
            class="addBtn"
            @click.stop="onOpenAddDeptDialog"
          >
            添加部门
          </el-button>
        </div>
        <el-table
          :data="searchDate"
          row-key="id"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          style="width: 100%"
          default-expand-all
          :header-cell-style="{
            background: '#F5F7FA',
            color: '#606266',
          }"
        >
          <el-table-column prop="name" label="部门名称"> </el-table-column>
          <el-table-column prop="member_count" label="部门人数">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="color: #3468fe"
                @click="onAddChildrenDept(scope.row)"
              >
                添加下级部门
              </el-button>
              <el-button
                class="button-edit"
                type="text"
                size="small"
                style="color: #3468fe"
                v-if="scope.row.top_id != 0"
                @click="onEditorialDepartment(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                style="color: #f56c6c"
                v-if="scope.row.top_id != 0"
                @click="onDelDepSubmit(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>

    <el-dialog
      width="40%"
      title="编辑"
      :visible.sync="isShowEditDeptDialog"
    >
      <el-form :model="addDepartmentForm" ref="deptAddEditForm" :rules="rules">
        <el-form-item
          label="部门名称:"
          prop="name"
          :label-width="formLabelWidthAdd"
        >
          <el-input
            class="search-input-dialog"
            type="text"
            placeholder="请输入部门名称"
            maxlength="32"
            name="name"
            v-model.trim="addDepartmentForm.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="上级部门: "
          prop="top_name"
          :label-width="formLabelWidthAdd"
        >
          <div class="selectOrg-content" style="position: relative">
            <el-input
              v-model="addDepartmentForm.top_name"
              @focus="showModalTree = true"
              clearable
              readonly
              :maxlength="40"
              placeholder="请选择组织"
              class="input-max-width"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div class="modalTree" v-if="showModalTree">
                <el-tree
                  ref="tree"
                  :data="departmentList"
                  default-expand-all
                  @node-click="handleNodeClick"
                  :expand-on-click-node="false"
                  style="left: 60px; width: 57%"
                ></el-tree>
              </div>
            </transition>
            <div
              class="treeModal"
              @click="showModalTree = false"
              v-if="showModalTree"
            ></div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowEditDeptDialog = false">取 消</el-button>
        <el-button type="primary" class="buttonColor" @click="onDeptSaveSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      width="40%"
      title="添加下级部门"
      :visible.sync="addSubordinateVisible"
    >
      <el-form :model="addDeptSubForm" ref="addDeptSubForm">
        <el-form-item
          label="部门名称:"
          prop="newName"
          :label-width="formLabelWidthAdd"
          :rules="[
            { required: true, message: '请输入部门名称', trigger: 'blur' },
            {
              min: 2,
              max: 20,
              message: '长度在 2 到 20个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            class="search-input-dialog"
            type="text"
            placeholder="请输入部门名称"
            maxlength="32"
            key="newName"
            name="newName"
            v-model.trim="addDeptSubForm.newName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级部门:" :label-width="formLabelWidthAdd">
          <el-input
            class="search-input-dialog"
            type="text"
            :disabled="true"
            placeholder="请输入姓名或手"
            maxlength="10"
            v-model="addDeptSubForm.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addSubordinateCancel">取 消</el-button>
        <el-button
          type="primary"
          class="buttonColor"
          @click="onAddChildrenDeptSubmit('addDeptSubForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除 -->
  </div>
</template>
<script>
import request from "@request/api/index";
import SearchInput from "@components/SearchInput";
import { deepClone, deepFind } from "@/utils/utils";
export default {
  name: "DepartmentPane",
  components: {
    SearchInput,
  },
  props: {
    searchDate: Array,
    departmentList: Array,
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      searchMes: {},
      addForm: {},
      addDepartmentForm: {},
      addSubordinateVisible: false,
      isShowEditDeptDialog: false,
      addDeptSubForm: {
        newName: "",
      },
      aubordinateForm: {},
      filterText: "",
      currentPage4: 1,
      formLabelWidth: "60px",
      formLabelWidthAdd: "120px",
      delId: "",
      input: "",
      showModalTree: false,
      searchInputText: "",
      rules: {
        name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.data = this.data0;
    console.log('dtpe-pane');
  },
  methods: {
    //编辑部门
    onEditorialDepartment(row) {
      this.showModalTree = false;
      this.addDepartmentForm = deepClone(row);
      this.isShowEditDeptDialog = true;
    },
    // 添加部门
    addSubordinateCancel() {
      this.addSubordinateVisible = false;
    },
    onOpenAddDeptDialog() {
      this.$emit("OpenAddDeptDialog");
    },
    onDeptSaveSubmit() {
      try {
        this.$refs.deptAddEditForm.validate((valid) => {
          if (valid) {
            let params = {
              name: this.addDepartmentForm.name,
              id: this.addDepartmentForm.id,
              top_id: this.addDepartmentForm.top_id,
            };
            request.organization
              .requestEditDepartment(params)
              .then((result) => {
                if (result.data.code == 1000) {
                  this.$message.success("编辑成功！");
                  this.isShowEditDeptDialog = false;
                  this.$emit('getDepartmentPaneData');

                }
              });
          }
        });
      } catch (errr) {}
    },
    //添加下级部门
    onAddChildrenDept(row) {
      this.addDeptSubForm.top_id = row.id;
      this.addDeptSubForm.name = row.name;
      this.addSubordinateVisible = true;
    },
    onAddChildrenDeptSubmit(formName) {
      try {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              name: this.addDeptSubForm.newName,
              top_id: this.addDeptSubForm.top_id,
            };
            request.organization
              .requestAddDepartment(params)
              .then((nextData) => {
                if (nextData.data.code == 1000) {
                  this.$message.success("添加下级部门成功！");
                  this.addSubordinateVisible = false;
                  this.$emit('getDepartmentPaneData');
                  this.addDeptSubForm.newName = "";
                }
              });
          }
        });
      } catch (err) {}
    },
    //删除部门
    onDelDepSubmit(item) {
      try {
        this.$confirm(`确认删除该部门吗?  是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let params = {
              id: item.id,
            };
            request.organization.requestDelDepartment(params).then((resulr) => {
              if (resulr.data.code == 1000) {
                let searchResult = deepFind(
                  this.searchDate,
                  (depItem, index, level) => {
                    if (depItem.id === item.id) {
                      return depItem;
                    }
                  },
                  "children"
                );
                let delArrTpl = searchResult[searchResult.length - 2].children;
                delArrTpl.splice(
                  delArrTpl.findIndex((delItem) => delItem.id === item.id),
                  1
                );
                this.$message.success("删除成功！");
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } catch (err) {}
    },
    updateSearchList(str) {
      this.$emit("filterData", str);
      // this.filterData(str);
    },
    handleNodeClick(node) {
      this.addDepartmentForm.top_id = node.id;
      this.addDepartmentForm.top_name = node.label;
      this.showModalTree = false;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.departments {
  height: 100%;
}
.search-top {
  padding: 0 21px 15px 21px;
}
.el-card {
  border-radius: 8px;
}
.el-button--text {
  text-align: left;
}
.el-aside {
  height: 850px;
  // padding-right: 10px;
  border-right: 1px solid #ccc;
}
.title {
  margin-bottom: 20px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.el-main {
  background: #fff;
}
.join-add {
  width: 32px;
  height: 32px;
  font-size: 24px;
  text-align: center;
  line-height: 28px;
  display: inline-block;
  background: #ffff;
  margin-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.search-input-dialog {
  height: 0px;
}
.button-edit {
  margin-left: 40px !important;
}
</style>
