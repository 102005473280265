<template>
  <el-dialog width="40%"
    title="添加部门"
    :visible.sync="isShow"
    :before-close="close">
    <el-form :model="editForm"
      :validate-on-rule-change = "false"
      ref="addDeptForm"
      :rules="addDeptRules">
      <el-form-item label="部门名称:"
        prop="name"
        :label-width="formLabelWidthAdd">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入部门名称"
          maxlength="32"
          v-model="editForm.name"
          autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="top_name"
        label="上级部门: "
        :label-width="formLabelWidthAdd">
        <div class="selectOrg-content"
          style="position:relative">
          <el-input v-model="editForm.top_name"
            @focus="showModalTree = true"
            clearable
            readonly
            placeholder="请选择上级部门"
            class="input-max-width"
           ></el-input>
          <transition name="el-zoom-in-top">
            <div class="modalTree modalAdd"
              v-if="showModalTree">
              <el-tree ref="tree"
                :data="departmentList"
                default-expand-all
                @node-click="handleNodeClick"
                :expand-on-click-node="false"
                style="left:60px;width:57%"></el-tree>
            </div>
          </transition>
          <div class="treeModal"
            @click="showModalTree = false"
            v-if="showModalTree"></div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer"
      class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary"
        class="buttonColor"
        @click="onAddSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "add-department-dialog",
  props: {
    departmentList: Array,
  },
  data() {
    return {
      formLabelWidthAdd: "120px",
      isShow: false,
      showModalTree: false,
      editForm: {
        top_name:'',
        name:''
      },
      addDeptRules: {
        name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        top_name: [
          {
            required: true,
            message: "请输入选择上级部门",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.isShow = true;
      // this.$refs.addDeptForm.clearValidate();
    },
    close() {
      this.isShow = false;
      this.showModalTree = false
      this.editForm = {
        top_name:'',
        name:''
      };
      this.$refs.addDeptForm.resetFields();
    },
    onAddSubmit() {
      console.log(this.editForm);
      this.$refs.addDeptForm.clearValidate();

      this.$refs.addDeptForm.validate((valid) => {
        if (valid) {
          this.$emit('onAddSubmit',this.editForm)
        }else{
          return false;
        }
      });
    },
    handleNodeClick(node) {
      this.editForm.top_id = node.id;
      this.editForm.top_name = node.label;
      this.showModalTree = false;
      console.log( this.editForm);
      this.$refs.addDeptForm.clearValidate();

    },
  },
};
</script>