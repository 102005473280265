<template>
  <div class="demo-drawer__content">
    <el-form ref="refEditForm"
      :rules="rules"
      :model="editForm">
      <el-form-item prop="nickname"
        label="姓名:"
        :label-width="formLabelWidth">
        <el-input :disabled="true"
          class="search-input-dialog"
          type="text"
          placeholder="请输入姓名"
          v-model="editForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="手机号:"
        prop="phone"
        :label-width="formLabelWidth">
        <el-input :disabled="true"
          class="search-input-dialog"
          type="text"
          placeholder="请输入手机号"
          v-model="editForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱:"
        :label-width="formLabelWidth">
        <el-input :disabled="true"
          class="search-input-dialog"
          type="text"
          placeholder="请输入邮箱"
          v-model="editForm.email"></el-input>
      </el-form-item>
      <el-form-item label="部门:"
        prop="department_name"
        :label-width="formLabelWidth">
        <div class="selectOrg-content"
          style="position:relative">
          <el-input class="input-max-width"
            v-model="editForm.department_name"
            @focus="showModalTree = true"
            clearable
            readonly
            :maxlength="40"
            placeholder="请选择部门"></el-input>
          <transition name="el-zoom-in-top">
            <div class="modalTree"
              v-if="showModalTree">
              <el-tree ref="tree"
                :data="departmentList"
                default-expand-all
                @node-click="handleNodeClick3"
                :expand-on-click-node="false"
                style="left:30px;width:57%"></el-tree>
            </div>
          </transition>
          <div class="treeModal"
            @click="showModalTree = false"
            v-if="showModalTree"></div>
        </div>

      </el-form-item>
      <el-form-item label="职位:"
        prop="position"
        :label-width="formLabelWidth">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入职位"
          v-model="editForm.position"></el-input>
      </el-form-item>
      <el-form-item label="媒资库权限:"
        prop="mediaAuth"
        :label-width="formLabelWidth">
        <el-cascader v-model=editForm.mediaAuth
         class="input-max-width"
          :options="mediaAuthOps"
          :props="{ multiple: true }"
          clearable></el-cascader>
      </el-form-item>

    </el-form>

    <div class="demo-drawer__footer"
      style="text-align:center">
      <el-button type="primary"
        class="buttonColor"
        @click="onSaveSubmit">保存</el-button>
      <template v-if="isShowStartStopDelBtn(editForm)">
        <el-button v-if="editForm.status == 1"
          @click="onSaveStatus(0)"
          class="suspend">停用</el-button>
        <el-button class="suspend"
          v-if="editForm.status == 0"
          @click="onSaveStatus(1)">启用</el-button>
        <el-button type="danger"
          @click="onDelSubmit">删除</el-button>
      </template>
    </div>

  </div>

</template>
<script>
import memberMix from './mixins/memberMix.js'
 
export default {
  name: "member-right-drawer-content",
  mixins:[memberMix],
  props: {
    editForm: Object,
    departmentList: Array,
  },
  data() {
    return {
      formLabelWidth: "120px",
      showModalTree: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        nickname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入电话号码",
            trigger: "blur",
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        department_name: [
          {
            required: true,
            message: "请选择部门",
            trigger: "change",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入职位",
            trigger: "change",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSaveSubmit() {
      this.$refs.refEditForm.validate((valid) => {
        if (valid) {
          this.$emit("onSaveFormSubmit", this.editForm);
        }
      });
    },
    onSaveStatus(type) {
      const text =
        type == 0 ? "停用后该账号将暂停使用" : "启用后该账号将恢复使用";
      this.$confirm(`${text}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("onChangeStatusSubmit", type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onDelSubmit() {
      this.$confirm(`删除后将无法找回, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("onDelMemberSubmit", this.editForm);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleNodeClick3(node) {
      this.editForm.top_id = node.id;
      this.editForm.department_id = node.id;
      this.editForm.department_name = node.label;
      this.showModalTree = false;
      this.$forceUpdate();
    },
    isShowStartStopDelBtn(item) {
      if (item.identity === this.$constant.identity.enterpriseAdmin) {
        return false;
      }
      if (this.$store.state.user.userInfo.uId === item.user_id) {
        return false;
      }
      return true;
    },
  },
};
</script>