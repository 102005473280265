<template>
  <div class="departments">
    <el-breadcrumb class="breadcrumb-title"
      separator="/">
      <el-breadcrumb-item>组织管理</el-breadcrumb-item>
      <el-breadcrumb-item>成员与列表</el-breadcrumb-item>

    </el-breadcrumb>
    <div class="mainLeft">
      <el-card>
        <el-tabs v-model="activeName"
          @tab-click="handleClick">
          <el-tab-pane name="first"
            class="flex-row">
            <span slot="label"><i class=" iconfont iconyuangongguanli icon_gongguanli"></i>成员</span>
            <el-container style="overflow:hidden;">
              <el-aside style="width:200px;min-height:717px">
                <div class="flex-row ">
                  <el-input class="search-input"
                    type="text"
                    style="width:157px"
                    placeholder="搜索部门"
                    size="small"
                    suffix-icon=" iconfont iconsousuo"
                    v-model="filterText"></el-input>
                  <span class="join-add iconfont iconjiahao"
                    @click.stop="OpenAddDeptDialog"></span>
                </div>
                <el-tree class="filter-tree"
                  node-key="id"
                  :data="SearchDepartmentList"
                  :props="defaultProps"
                  :filter-node-method="filterNode"
                  :default-expanded-keys='leftTreeDefaultExpandedKey'
                  @node-click="handleNodeClick1"
                  ref="tree">
                </el-tree>
              </el-aside>
              <el-main>
                <div class="search-top flex-content-between">
                  <div>
                    <span class="title">{{checkedDeptName}}</span>
                    <i class=" iconfont iconyuangongguanli icon_gongguanli"
                      style="margin-left:5px;color:#D8D8D8;"></i>
                    <span>{{checkedDeptMemberCount}}</span>
                  </div>
                  <div>
                    <el-button type="primary"
                      class="addBtn"
                      @click.stop="addMember">
                      添加成员
                    </el-button>
                    <el-button type="primary"
                      class="addBtn"
                      @click.stop="toImport">
                      批量导入
                    </el-button>
                  </div>
                </div>
                <el-table :data="tableData"
                  max-height="750"
                  :header-cell-style="{
                    background: '#F5F7FA',
                    color: '#606266',
                  }">
                  <el-table-column prop="nickname"
                    label="姓名">
                  </el-table-column>
                  <el-table-column prop="department_name"
                    label="部门">
                  </el-table-column>
                  <el-table-column prop="position"
                    label="职位">
                  </el-table-column>
                  <el-table-column prop="phone"
                    label="手机号">
                  </el-table-column>
                  <el-table-column prop="email"
                    label="邮箱"> </el-table-column>
                  <el-table-column label="状态">
                    <template slot-scope="scope">
                      <el-button type="text"
                        v-if="scope.row.status == 1"
                        size="small"
                        style="color: #3468FE;">
                        已启用
                      </el-button>
                      <el-button type="text"
                        v-if="scope.row.status == 0"
                        style="color:#F56C6C"
                        size="small">
                        已停用
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="text"
                        @click="rowClick(scope.row)"
                        size="small"
                        style="color: #3468FE;">
                        编辑
                      </el-button>
                    </template>
                  </el-table-column>

                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                  :current-page="curPage"
                  :page-size="pageSize"
                  :page-sizes="[pageSize]"
                  layout="prev, pager, next, sizes, jumper , total"
                  background
                  :total="pageDate.length">
                </el-pagination>
              </el-main>
            </el-container>
          </el-tab-pane>
          <el-tab-pane name="second">
            <span slot="label"><i class=" iconfont iconqiyeguanli icon_gongguanli"></i>部门</span>
            <DepartmentPane @filterData="filterDataDepartment"
              @OpenAddDeptDialog="OpenAddDeptDialog"
              @getSearchDepartment=getSearchDepartment
              @getDepartmentPaneData=getDepartmentPaneData
              :departmentList="departmentList"
              :searchDate="searchDateSon"
              ref='refDepartmentDate'>
            </DepartmentPane>
          </el-tab-pane>
        </el-tabs>
        <div style="position:absolute;top:24px ;right:24px"
          class="pointer blue"
          @click="onClickRoleExplain">角色权限说明
          <i class="el-icon-info"></i>
        </div>
      </el-card>
      <!-- 右侧窗 -->
      <el-drawer :visible.sync="isShowDrawer"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
        :modal-append-to-body="false">
        <RightDrawerContent v-if="isShowDrawer"
          :departmentList=departmentList
          @onDelMemberSubmit=onDelMemberSubmit
          @onSaveFormSubmit=onSaveFormSubmit
          @onChangeStatusSubmit=onChangeStatusSubmit
          :editForm=curItem></RightDrawerContent>
      </el-drawer>
      <AddMemberDialog ref="refAddMemberDialog"
        @onAddSubmit=onAddMemberSubmit
        :departmentList=departmentList>
      </AddMemberDialog>
      <el-dialog width="30%"
        class="project-shooting"
        :visible.sync="ProjectShooting">
        <span class="del-content">
          <span class="iconfont icon-jianshi iconjingshi_jinshi-47"></span>
          该成员拥有项目或拍摄，请移交后再删除。</span>
        <div slot="footer"
          class="dialog-footer">
          <el-button @click="ProjectShooting = false">取 消</el-button>
          <el-button type="primary"
            class="buttonColor"
            @click="ProjectShooting=false">确 定</el-button>
        </div>
      </el-dialog>

      <AddDepartmentDialog ref="refAddDeptDialog"
        @onAddSubmit=onAddDepartmentSubmit
        :departmentList=departmentList></AddDepartmentDialog>
    </div>
    <RoleExcplainDialog ref="refRoleExcplainDialog"></RoleExcplainDialog>

  </div>
</template>
<script>
import request from "@request/api/index";
import DepartmentPane from "./components/DepartmentPane";
import { deepClone, deepFind } from "@/utils/utils";
import RightDrawerContent from "./components/RightDrawerContent";
import AddMemberDialog from "./components/AddMemberDialog";
import AddDepartmentDialog from "./components/AddDepartmentDialog";
import Constant from "@utils/constant";
import RoleExcplainDialog from "@components/RoleExplainDialog.vue";
import memberMix from "./components/mixins/memberMix.js";
export default {
  components: {
    DepartmentPane,
    RightDrawerContent,
    AddDepartmentDialog,
    AddMemberDialog,
    RoleExcplainDialog,
  },
  mixins: [memberMix],
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    pageDate() {
      let page = this.pageDate.length / this.pageSize + 1;
      if (this.pageDate.length % this.pageSize == 0) {
        page--;
      }
      if (this.curPage > 1 && this.curPage > page) {
        this.curPage = page;
      }
      this.computeList();
    },
  },

  data() {
    return {
      Constant: Constant,
      searchMes: {
        eventTitle: "",
      },
      SearchDepartmentList: [],
      checkedDeptName: this.$store.state.user.companyBasicInfo.company_name,
      checkedDeptMemberCount: 0,
      total: 0,
      addForm: {},
      curPage: 1,
      pageSize: 10,
      delFormVisible: false,
      isShowDrawer: false,
      ProjectShooting: false,
      formLabelWidthAdd: "120px",
      addMemberVisible: false,
      aubordinateForm: {},
      currentPage4: 1,
      activeName: "first",
      value: "",
      filterText: "",
      obj: {},
      pageDate: [],
      titleList: "",
      userlist: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      formLabelWidth: "80px",
      tableData: [],
      searchTableDate: [],
      editForm: {},
      searchId: "",
      showModalTree: false,
      addDepartmentVisible: false,
      addDepartmentForm: {},
      type: 1,
      departmentDataSon: [],
      searchDateSon: [],
      deptChildIds: [],
      leftTreeDefaultExpandedKey: [], //设置默认展开，取组织第一Id
      curItem: {
        mediaAuth: [],
      },
      orginCurItem: {},
      departmentList: [],
      isShowAddDeptDialog: false,
    };
  },
  created() {
    this.data = this.data0;
    this.getMember();
    this.getSearchDepartment();
  },
  methods: {
    onClickRoleExplain() {
      this.$refs.refRoleExcplainDialog.open();
    },
    filterDataDepartment(str) {
      let copyList = deepClone(this.departmentDataSon);
      if (str == "") {
        this.searchDateSon = this.departmentDataSon;
        return false;
      }
      let arr = [];
      for (let i = 0; i < copyList.length; i++) {
        if (copyList[i].name.indexOf(str) >= 0) {
          arr.push(copyList[i]);
        } else {
          //不在顶级父部门
          //每一个顶层部门
          let department = this.searchDate(copyList[i], str);
          if (department != null) {
            arr.push(department);
          }
        }
      }
      this.searchDateSon = arr;
      //this.departmentDataSon=arr;
    },
    //获取所有部门
    searchDate(department, str) {
      //debugger
      if (department == null) {
        return null;
      }
      if (str == "") {
        return department;
      }
      if (department.name.indexOf(str) >= 0) {
        return department;
      }
      let childrenArr = department.children;
      if (childrenArr == null) {
        return null;
      }
      for (let i = 0; i < childrenArr.length; i++) {
        let departmentChild = this.searchDate(childrenArr[i], str);
        if (departmentChild == null) {
          childrenArr.splice(i, 1);
          //department.children.splice(i,1);
          i--;
        }
      }
      return childrenArr == null || childrenArr.length == 0 ? null : department;
    },
    // 成员列表
    async getMember() {
      let params = {
        curr_page: this.curr_page,
      };
      let res = await request.organization.requestMember({ params });
      if (res.data.code == 1000) {
        res.data.data.list.forEach((item) => {
          if (item.department_id == 0) {
            // item.department_name = "上海新阅科技网络有限公司";
          }
        });
        this.tableData = res.data.data.list;
        this.searchTableDate = res.data.data.list;
        this.pageDate = res.data.data.list;
        this.checkedDeptMemberCount = res.data.data.count;
        this.total = res.data.data.count;
      }
    },
    // 部门搜索
    async getSearchDepartment() {
      const {
        data: {
          data: { list },
        },
      } = await request.organization.requestSearchDepartment({});
      this.SearchDepartmentList = list;
      if (this.SearchDepartmentList.length > 0) {
        this.leftTreeDefaultExpandedKey.push(this.SearchDepartmentList[0].id);
        //设置默认部门id
        if (this.addForm.top_id == "0") {
          this.addForm.top_id = this.SearchDepartmentList[0].id;
        }

        this.departmentList = list;
      }
    },
    OpenAddDeptDialog() {
      this.isShowAddDeptDialog = true;
      this.$refs.refAddDeptDialog.open();
    },
    // 添加成员
    onAddMemberSubmit(item) {
      try {
        let params = {
          name: item.name,
          phone: item.phone,
          email: item.email,
          department_id: item.top_id, //部门ID
          position: item.position, //职位
        };
        if (!item.top_id) {
          params.department_id = 0;
        }
        if (!item.email) {
          delete params.email;
        }
        this.formatSaveMediaAuth(params, item);
        request.organization.requestAddMember(params).then(({ data }) => {
          const { code } = data;
          if (data && code == 1000) {
            this.$message.success("添加成功！");
            this.getMember();
            this.$refs.refAddMemberDialog.close();
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    onSaveFormSubmit(item) {
      console.log("item,", item);
      try {
        let params = {
          name: item.nickname,
          phone: item.phone,
          email: item.email,
          department_id: item.department_id,
          position: item.position,
          user_id: item.user_id,
        };
        if (item.email == "") {
          delete params.email;
        }

        this.formatSaveMediaAuth(params, item);

        request.organization.requestEditMember(params).then(({ data }) => {
          if (data.code == 1000) {
            this.$message.success("编辑成功！");
            this.getMember();
            this.isShowDrawer = false;
          }
        });
      } catch (err) {}
    },
    //删除成员
    onDelMemberSubmit() {
      try {
        let params = {
          user_id: this.curItem.user_id,
        };
        request.organization.requestDelMember(params).then(({ data }) => {
          if (data.code == 1000) {
            this.$message.success("删除成功！");
            this.tableData.splice(
              this.tableData.findIndex((node) => node.id === this.curItem.id),
              1
            );
            this.pageDate.splice(
              this.pageDate.findIndex((node) => node.id === this.curItem.id),
              1
            );

            this.isShowDrawer = false;
            this.delFormVisible = false;
          }
        });
      } catch (err) {
        if (err.data.code == 3140) {
          this.delFormVisible = false;
          this.ProjectShooting = true;
        }
      }
    },
    rowClick(item) {
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields();
      }
      this.showModalTree = false;
      this.editForm = JSON.parse(JSON.stringify(item));

      item.mediaAuth = this.formatShowMediaAuth(item.media);
      this.isShowDrawer = true;
      // console.log(item)
      this.curItem = { ...item };
      this.orginCurItem = item;
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.computeList();
    },
    computeList() {
      let start = (this.curPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      this.tableData = this.pageDate.slice(start, end);
    },
    handleClick(tab) {
      this.filterText = "";
      this.tabIndex = tab.index;
      tab.index == 0
        ? this.getMember() && this.getSearchDepartment()
        : this.getDepartmentPaneData();
    },
    getDepartmentPaneData() {
      request.organization.requestDepartment({}).then((res) => {
        this.departmentDataSon = res.data.data.list;
        this.searchDateSon = res.data.data.list;
      });
    },
    filterNode(value, data, node) {
      if (!value) return true;
      let parentNode = node.parent;
      let labels = [node.label];
      let level = 1;
      while (level < node.level) {
        labels = [...labels, parentNode.label];
        parentNode = parentNode.parent;
        level++;
      }
      return labels.some((label) => label.indexOf(value) !== -1);
    },
    addMember() {
      this.addForm = {};
      this.showModalTree = false;
      this.addForm = {
        value: this.SearchDepartmentList[0].label,
        top_id: this.SearchDepartmentList[0].top_id,
      };
      this.getSearchDepartment();
      this.addMemberVisible = true;
      this.$refs.refAddMemberDialog.open();
    },
    // 编辑成员状态
    onChangeStatusSubmit(status) {
      try {
        let params = {
          status: status,
          user_id: this.curItem.user_id,
        };
        request.organization.requestStatus(params).then(({ data }) => {
          console.log(data, "data");
          if (data.code == 1000) {
            this.$message.success("状态变更成功！");
            this.orginCurItem.status = status;
            this.isShowDrawer = false;
            this.delFormVisible = false;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    filterData() {
      let copyList = JSON.parse(JSON.stringify(this.searchDate));

      let arr = [];
      for (let i = 0; i < copyList.length; i++) {
        if (this.searchInputText != "") {
          if (copyList[i].name.indexOf(this.searchInputText) >= 0) {
            arr.push(copyList[i]);
          }
        } else {
          this.departmentData = this.searchDate;
          return false;
        }
      }
      this.departmentData = arr;
    },
    updateSearchList(str) {
      this.searchInputText = str;
      this.filterData();
    },
    handleDeptChildTreeIds(depts) {
      for (let i = 0; i < depts.length; i++) {
        if (depts[i]) {
          this.deptChildIds.push(depts[i].id);
          if (depts[i].children) {
            this.handleDeptChildTreeIds(depts[i].children);
          }
        }
      }
    },
    handleNodeClick1(node) {
      this.tableData = [];
      this.deptChildIds = [];
      const allDept = deepClone(this.SearchDepartmentList);
      const searchResult = deepFind(
        allDept,
        (item, index, level) => item.id === node.id,
        "children"
      );
      const checkedDept = searchResult.pop();
      this.handleDeptChildTreeIds([checkedDept]);
      this.checkedDeptName = checkedDept.label; //选中部门名称
      let deptMemberCount = 0;
      this.searchTableDate.forEach((element) => {
        if (
          this.deptChildIds.includes(element.department_id) ||
          node.top_id === "0"
        ) {
          deptMemberCount++;
          this.tableData.push(element);
        }
      });
      this.checkedDeptMemberCount =
        node.top_id === "0" ? this.total : deptMemberCount;
      this.pageDate = this.tableData;
      this.curPage = 1;
    },
    // 添加部门提交
    onAddDepartmentSubmit(item) {
      try {
        let params = {
          name: item.name,
          top_id: item.top_id,
        };
        request.organization.requestAddDepartment(params).then(({ data }) => {
          if (data.code == 1000) {
            this.$message.success("添加成功！");
            this.tabIndex == 1
              ? this.getDepartmentPaneData()
              : this.getSearchDepartment();
            this.addDepartmentVisible = false;
            this.addSubordinateVisible = false;
            this.isShowAddDeptDialog = false;
            this.$refs.refAddDeptDialog.close();
          }
        });
      } catch (err) {}
    },
    // 导入
    toImport() {
      this.$router.push({
        path: "/importPage",
        query: {
          type: this.type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// @import '@/assets/style/page.scss';
.icon_gongguanli {
  margin-right: 5px;
  font-size: 18px;
}
.search-top {
  padding: 0 19px 15px 15px;
}
.el-card {
  position: relative;
  border-radius: 8px;
}
.el-button--text {
  text-align: left;
}
.mainLeft {
  .member-tree {
    width: 20%;

    .join-add {
      width: 40px;
      height: 32px;
      font-size: 24px;
      text-align: center;
      line-height: 32px;
      display: inline-block;
      background: #ffff;
      margin-left: 10px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .member-data {
    padding-left: 50px;
    background: #fff;
    width: 80%;
  }
}
.el-aside {
  height: 850px;
  border-right: 1px solid #ebeef5;
}
.title {
  margin-bottom: 20px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.el-main {
  background: #fff;
}
.join-add {
  width: 32px;
  height: 32px;
  font-size: 24px;
  text-align: center;
  line-height: 28px;
  display: inline-block;
  background: #ffff;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}
.iconjiahao {
  color: #8e828294;
  font-size: 22px;
}
.button-edit {
  margin-left: 40px !important;
}
.icon-jianshi {
  color: #ff9812;
  font-size: 28px;
}
</style>