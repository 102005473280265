<template>
  <!-- 添加成员 -->
  <el-dialog width="40%"
    title="添加成员"
    :before-close=close
    :visible.sync="isShow">
    <el-form ref="addUserForm"
      :rules="rules"
      :model="addForm">
      <el-form-item prop="name"
        label="姓名:"
        :label-width="formLabelWidthAdd">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入姓名"
          @input="change"
          v-model="addForm.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号:"
        prop="phone"
        :label-width="formLabelWidthAdd">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入手机号"
          @input="change"
          v-model="addForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="邮箱:"
        :label-width="formLabelWidthAdd">
        <el-input class="search-input-dialog"
          type="text"
          placeholder="请输入邮箱"
          @input="change"
          v-model="addForm.email"
          autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="部门:"
        prop="value"
        style="position:relative;"
        :label-width="formLabelWidthAdd">
        <el-input v-model="addForm.value"
          @focus="showModalTree = true"
          clearable
          readonly
          :maxlength="40"
          @input="change"
          placeholder="请选择部门"
          class='input-max-width'></el-input>
        <transition name="el-zoom-in-top">
          <div class="modalTree"
            v-if="showModalTree">
            <el-tree ref="tree"
              :data="departmentList"
              default-expand-all
              @node-click="handleNodeClick2"
              :expand-on-click-node="false"
              style="left:30px;width:57%">
              <span class="custom-tree-node"
                slot-scope="{ node, data }">
                <span style="position:relative;padding-left:20px;">
                  <img style="position:absolute;top:-2px;left:0px;"
                    width="16"
                    :src="'img/' + node.icon + '.png'"
                    alt />
                  {{ node.label }}
                </span>
              </span>
            </el-tree>
          </div>
        </transition>
        <div class="treeModal"
          @click="showModalTree = false"
          v-if="showModalTree"></div>
      </el-form-item>
      <el-form-item label="职位:"
        prop="position"
        :label-width="formLabelWidthAdd">
        <el-input class="input-max-width"
          type="text"
          placeholder="请输入职位"
          v-model="addForm.position"></el-input>
      </el-form-item>
      <el-form-item label="媒资库权限:"
        :label-width="formLabelWidthAdd">
        <el-cascader v-model=addForm.mediaAuth
          class="input-max-width"
          :options="mediaAuthOps"
          :props="{ multiple: true }"
          clearable></el-cascader>
      </el-form-item>
    </el-form>
    <div slot="footer"
      class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary"
        class="buttonColor"
        @click="onAddSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import memberMix from "./mixins/memberMix.js";
export default {
  name: "add-member",
  props: {
    departmentList: Array,
  },
  mixins: [memberMix],
  data() {
    return {
      formLabelWidthAdd: "120px",
      isShow: false,
      showModalTree: false,
      addForm: {
        name: "",
        phone: "",
        email: "",
        position: "",
        value: "",
        mediaAuth: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "change",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
        nickname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入电话号码",
            trigger: "change",
          },
          {
            pattern: /^1[0-9]{10}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        value: [
          {
            required: true,
            message: "请选择部门",
            trigger: "change",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入职位",
            trigger: "change",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    change() {
      this.$forceUpdate();
    },
    open() {
      this.defaultSelected(this.addForm);
      console.log(this.addForm);
      this.isShow = true;
    },
    close() {
      this.showModalTree = false;
      this.addForm.name = "";
      this.addForm.email = "";
      this.addForm.phone = "";
      this.addForm.value = "";
      this.addForm.position = "";

      this.$refs.addUserForm.resetFields();
      this.isShow = false;
    },
    onAddSubmit() {
      // this.addForm.auth = [];
      // if (this.addForm.mediaAuth) {
      //   let mediaAuth = this.addForm.mediaAuth;
      //   mediaAuth.forEach((element) => {
      //     this.addForm.auth.push(element[element.length - 1]);
      //   });
      // }
      this.$refs.addUserForm.validate((valid) => {
        if (valid) {
          this.$emit("onAddSubmit", this.addForm);
        }
      });
    },
    handleNodeClick2(node) {
      this.addForm.top_id = node.id;
      this.addForm.value = node.label;
      this.showModalTree = false;
      this.$refs.addUserForm.clearValidate();
    },
  },
};
</script>